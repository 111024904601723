<template>
  <v-container fluid class="ma-0">
    <v-row no-gutters justify="center">
      <v-col
        cols="11"
        lg="10"
        class="text-left mt-10 mb-10 reform_primary--text text-h4"
      >
        상담요청 조회
      </v-col>
    </v-row>
    <v-row no-gutters justify="center">
      <v-col cols="11" lg="10" class="mb-5">
        <v-row no-gutters justify="end" align="center">
          <v-col cols="auto">
            <v-select
              outlined
              hide-details
              dense
              color="reform_primary"
              item-color="reform_primary"
              :items="['전체', '상담완료', '상담미완료']"
              v-model="selectValue"
              style="width: 200px"
              @change="selectBookFromDrId_Init"
            >
            </v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center" v-show="bookList.length > 0">
      <v-col cols="11" lg="10">
        <div class="scroll-body">
          <div class="scroll-content">
            <v-row
              no-gutters
              class="pt-2 pb-2 bb-lightgray grey--text"
              align="center"
            >
              <v-col cols="1" class="text-center">
                <v-btn
                  @click="check_all"
                  plain
                  elevation="0"
                  color="reform_primary"
                  x-large
                >
                  전체선택
                </v-btn>
              </v-col>
              <v-col cols="2" class="text-center"> 등록일 </v-col>
              <v-col cols="1" class="text-center"> 이름 </v-col>
              <v-col cols="1" class="text-center"> 진료과목 </v-col>
              <v-col cols="2" class="text-center"> 희망날짜 </v-col>
              <v-col cols="1" class="text-center"> 희망시간 </v-col>
              <v-col cols="2" class="text-center"> 연락처 </v-col>
              <v-col cols="1" class="text-center"> 진료여부 </v-col>
              <v-col cols="1" class="text-center"> 내용보기 </v-col>
            </v-row>
            <v-row
              style="font-size: 18px; border-bottom: 1px solid lightgray"
              v-for="(item, index) in bookList"
              :key="index + 'A'"
              class="receive-patient-list pt-2 pb-2"
              no-gutters
              align="center"
            >
              <v-col cols="1" class="text-center"
                ><v-checkbox
                  color="reform_primary"
                  hide-details
                  dense
                  v-show="item.bookYn === 'N'"
                  :value="item.bookId"
                  v-model="selected"
                >
                </v-checkbox>
                <div v-show="item.bookYn === 'Y'">완료</div>
              </v-col>
              <v-col cols="2" class="text-center"> {{ item.bookDt }} </v-col>
              <v-col cols="1" class="text-center"> {{ item.bookNm }} </v-col>
              <v-col cols="1" class="text-center"> {{ item.mediItem }} </v-col>
              <v-col cols="2" class="text-center"> {{ item.hopeDt }} </v-col>
              <v-col cols="1" class="text-center"> {{ item.hopeTm }} </v-col>
              <v-col cols="2" class="text-center"> {{ item.hpNo }} </v-col>
              <v-col cols="1" class="text-center">
                {{ item.mediYn === "Y" ? "재진" : "초진" }}
              </v-col>
              <v-col cols="1" class="text-center">
                <v-img
                  src="@/assets/images/icons/note_alt_black_24dp.svg"
                  contain
                  max-height="30px"
                  min-height="30px"
                  height="30px"
                  @click="openConts(index)"
                  v-show="containConts(item.conts)"
                ></v-img>
                <div v-show="!containConts(item.conts)">없음</div>
              </v-col>
              <v-col
                cols="12"
                class="mt-2 pl-5 pr-5"
                v-show="memoCheck === index"
              >
                <v-textarea
                  outlined
                  no-resize
                  hide-details
                  dense
                  readonly
                  auto-grow
                  rows="2"
                  :value="item.conts"
                >
                </v-textarea>
              </v-col>
            </v-row>
            <!-- 페이지네이션 -->
          </div>
          <!-- 페이지네이션 -->
        </div>
        <v-col cols="auto">
          <v-btn
            color="reform_primary"
            :dark="selected.length > 0"
            elevation="0"
            @click="bookCheck"
            :disabled="selected.length === 0"
          >
            상담완료
          </v-btn>
        </v-col>
        <v-row align="center" justify="center" no-gutters>
          <v-col cols="12" class="text-center pa-0 ma-0">
            <v-item-group>
              <v-container>
                <v-row no-gutters justify="center">
                  <!-- < -->
                  <v-col
                    cols="auto pa-2"
                    class="text-center"
                    v-show="listStatus.pageTab > 1"
                  >
                    <v-btn
                      color="lightgrey"
                      class="align-center text-center pa-0 ma-0"
                      rounded
                      min-width="30px"
                      width="30px"
                      height="30px"
                      elevation="0"
                      @click="pageTabLoad(false)"
                    >
                      {{ "<" }}
                    </v-btn>
                  </v-col>
                  <!-- 번호 -->
                  <v-col
                    v-for="n in listStatus.currentPageCnt"
                    :key="n"
                    cols="auto pa-2"
                    class="text-center"
                  >
                    <v-btn
                      :color="
                        listStatus.currentPage === n
                          ? 'reform_primary'
                          : 'lightgrey'
                      "
                      class="align-center text-center pa-0 ma-0"
                      :dark="listStatus.currentPage === n ? true : false"
                      rounded
                      min-width="30px"
                      width="30px"
                      height="30px"
                      @click="pageLoad(n)"
                      elevation="0"
                    >
                      {{ (listStatus.pageTab - 1) * listStatus.pageCount + n }}
                    </v-btn>
                  </v-col>
                  <!-- > -->
                  <v-col
                    cols="auto pa-2"
                    class="text-center"
                    v-show="
                      listStatus.pageTab *
                        listStatus.dataOnPage *
                        listStatus.pageCount <
                      listStatus.totalDataCnt
                    "
                    @click="pageTabLoad(true)"
                  >
                    <v-btn
                      color="lightgrey"
                      class="align-center text-center pa-0 ma-0"
                      rounded
                      min-width="30px"
                      width="30px"
                      height="30px"
                      elevation="0"
                    >
                      {{ ">" }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-item-group>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      no-gutters
      justify="center"
      align="center"
      v-show="bookList.length === 0"
    >
      <v-col cols="auto"> 조회된 항목이 없습니다. </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      bookList: [],
      listStatus: {
        totalDataCnt: null, // 조회하여 가져온 총 데이터의 수
        dataOnPage: 10, // 한 페이지에 내려줄 데이터의 수
        pageCount: 5, // 한 페이지에 1~5까지 보여준다.
        totalPage: null, // 페이지네이션에 출력한 총 페이지의 수
        currentPage: 1, // 현재 페이지
        pageTab: 1,
        currentPageCnt: null, //현재 페이지네이션 개수
      },
      memoCheck: undefined,
      selectValue: "전체",
      paginationSet_trigger: true,
      selected: [],
    };
  },
  computed: {
    ...mapGetters("book", ["GET_BOOK_LIST", "GET_TOTAL_CNT"]),
  },
  created() {
    this.selectBookFromDrId();
  },
  methods: {
    ...mapActions("book", ["ADD_BOOK", "SELECT_FROM_DRID", "BOOK_CHECK"]),
    initStatus() {
      this.listStatus.totalDataCnt = null; // 조회하여 가져온 총 데이터의 수
      this.listStatus.dataOnPage = 10; // 한 페이지에 내려줄 데이터의 수
      this.listStatus.pageCount = 5; // 한 페이지에 1~5까지 보여준다.
      this.listStatus.totalPage = null; // 페이지네이션에 출력한 총 페이지의 수
      this.listStatus.currentPage = 1; // 현재 페이지
      this.listStatus.pageTab = 1;
      this.listStatus.currentPageCnt = 5; //현재 페이지네이션 개수
      this.memoCheck = undefined;
    },
    async selectBookFromDrId() {
      const payload = {
        drId: localStorage.getItem("drId"),
        recordsPerPage: this.listStatus.dataOnPage,
        firstIndex:
          this.listStatus.dataOnPage *
          (this.listStatus.currentPage -
            1 +
            (this.listStatus.pageTab - 1) * this.listStatus.pageCount),
        searchValue: this.selectValue,
      };
      try {
        await this.SELECT_FROM_DRID(payload).then(() => {
          this.bookList = this.GET_BOOK_LIST;
          this.listStatus.totalDataCnt = this.GET_TOTAL_CNT;
          this.selected = [];
          this.paginationSet();
        });
      } catch (error) {
        console.log("error:", error);
      }
    },
    pageLoad(n) {
      this.memoCheck = undefined;
      this.listStatus.currentPage = n;
      this.selectBookFromDrId();
    },
    pageTabLoad(next) {
      next ? (this.listStatus.pageTab += 1) : (this.listStatus.pageTab -= 1);
      this.paginationSet_trigger = true;
      this.pageLoad(1);
    },
    paginationSet() {
      if (this.paginationSet_trigger) {
        this.listStatus.totalPage = parseInt(
          this.listStatus.totalDataCnt / this.listStatus.dataOnPage
        );
        if (this.listStatus.totalDataCnt % this.listStatus.dataOnPage > 0)
          this.listStatus.totalPage += 1;

        let tp = this.listStatus.totalPage;
        let pt = this.listStatus.pageTab;
        let pc = this.listStatus.pageCount;
        let cpc;
        tp >= pt * pc ? (cpc = pc) : (cpc = tp % pc);
        this.listStatus.currentPageCnt = cpc;
        this.memoCheck = undefined;
        this.paginationSet_trigger = false;
      }
    },
    openConts(val) {
      if (this.memoCheck === val) this.memoCheck = undefined;
      else this.memoCheck = val;
    },
    containConts(conts) {
      if (conts === null || conts === undefined || conts === "") return false;
      return true;
    },
    selectBookFromDrId_Init() {
      this.paginationSet_trigger = true;
      this.initStatus();
      this.selectBookFromDrId();
    },
    check_all() {
      if (this.selected.length > 0) {
        this.selected = [];
        return;
      }
      for (let i = 0; i < this.bookList.length; i++) {
        if (this.bookList[i].bookYn === "N")
          this.selected.push(this.bookList[i].bookId);
      }
    },
    async bookCheck() {
      let text = "";
      for (let i = 0; i < this.selected.length; i++) {
        text += this.selected[i] + "@#@#";
      }
      const payload = {
        drId: localStorage.getItem("drId"),
        searchValue: text,
      };
      try {
        await this.BOOK_CHECK(payload).then(() => {
          this.selectBookFromDrId_Init();
        });
      } catch (error) {
        console.log("error:", error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/scrollObj";
@import "@/assets/css/request";
input,
textarea {
  -moz-user-select: auto;

  -webkit-user-select: auto;

  -ms-user-select: auto;

  user-select: auto;
}
</style>
<style>
.bb-lightgray {
  border-bottom: 1px solid lightgrey;
}

.title-text {
  color: gray;
}

.v-input__slot {
  align-items: center;
  justify-content: center;
  margin-left: 2px;
}
</style>
